<template>
	<div class="m22 wrapper">
		<div class="m22-slide">
			<Swiper ref="mySwiper" :options="swiperOptions">
				<SwiperSlide>
					<div class="img-KP-APP-SC-129-main">
						<!-- <img
              :src="require('@/assets/img/img_m_detail.png')"
              alt="슬라이드 이미지1"
            /> -->
						<p>환영합니다.</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div class="img-KP-APP-SC-129-main">
						<!-- <img
              :src="require('@/assets/img/img_m_detail.png')"
              alt="슬라이드 이미지1"
            /> -->
						<p>환영합니다.</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div class="img-KP-APP-SC-129-main">
						<!-- <img
              :src="require('@/assets/img/img_m_detail.png')"
              alt="슬라이드 이미지1"
            /> -->
						<p>환영합니다.</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div class="img-KP-APP-SC-129-main">
						<!-- <img
              :src="require('@/assets/img/img_m_detail.png')"
              alt="슬라이드 이미지1"
            /> -->
						<p>환영합니다.</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div class="img-KP-APP-SC-129-main">
						<!-- <img
              :src="require('@/assets/img/img_m_detail.png')"
              alt="슬라이드 이미지1"
            /> -->
						<p>환영합니다.</p>
					</div>
				</SwiperSlide>
				<div class="swiper-pagination" slot="pagination"></div>
			</Swiper>
			<div class="box-shadow"></div>
		</div>

		<div class="btn-app">
			<button @click="skip" type="button" class="btn-select">건너뛰기</button>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
	name: 'Introduce',
	components: {
		Swiper,
		SwiperSlide,
	},

	computed: {
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},

	data() {
		return {
			swiperOptions: {
				pagination: {
					el: '.swiper-pagination',
				},
				// Some Swiper option/callback...
			},
		};
	},

	created() {},

	methods: {
		skip() {
			console.log('skip');
			localStorage.setItem('shownIntoroduce', true);
			this.$router.replace({
				path: '/',
			});
		},
	},
};
</script>
